.table.no-border td {
    border: none;
}

.table.vertical-center td {
    vertical-align: middle;
}

.table th.spaced {
    padding-top: 1.5em;
}

.not-hoverable:hover {
    background-color: transparent !important;
}

.DayPickerInput-Overlay, .DayPicker-Day {
    border-radius: 4px !important;
}

.firebaseui-idp-anonymous.firebaseui-id-idp-button {
    margin-top: 15px;
}

.is-centered.is-checkradio[type=checkbox]+label::before, .is-centered.is-checkradio[type=checkbox]+label:before, .is-centered.is-checkradio[type=radio]+label::before, .is-centered.is-checkradio[type=radio]+label:before {
    top: 6%;
}

.is-centered.is-checkradio[type=checkbox]+label::after, .is-centered.is-checkradio[type=checkbox]+label:after, .is-centered.is-checkradio[type=radio]+label::after, .is-centered.is-checkradio[type=radio]+label:after {
    top: calc( 6% + 0.405rem );
}